import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

export const formatDate = (date: string) => {
  return dayjs(date).tz('Australia/Melbourne').format();
};

export const getIdFromGraphQlId = (ID: any, replaceString: string) => {
  const idString = Buffer.from(ID, 'base64').toString()
  return idString.replace(replaceString, '');
};
