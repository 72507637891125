import '@assets/chrome-bug.css'
import '@assets/main.css'
import 'keen-slider/keen-slider.min.css'
import Link from 'next/link'
import { Router } from 'next/router'
import { FC, PropsWithChildren, ReactNode, useEffect } from 'react'

import { Head } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { linkResolver, repositoryName } from '@lib/prismicio'
import { PrismicPreview } from '@prismicio/next'
import { PrismicProvider } from '@prismicio/react'
import type { AppProps } from 'next/app'
import NProgress from 'nprogress'
import ShopifyTracking from '@lib/hydrogen-react/ShopifyTracking'

const Noop: FC<PropsWithChildren> = ({ children }) => <>{children}</>

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => {
  NProgress.start()
})

Router.events.on('routeChangeComplete', () => {
  NProgress.done()
})

Router.events.on('routeChangeError', () => {
  NProgress.done()
})

export default function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  return (
    <>
      <Head />
      <ShopifyTracking analyticsPayload={pageProps?.analyticsPayload} />
      <ManagedUIContext>
        <Layout pageProps={pageProps}>
          <PrismicProvider
            linkResolver={linkResolver}
            internalLinkComponent={({ href, children, ...props }) => (
              <Link href={href} prefetch={false} {...props}>
                {children as ReactNode}
              </Link>
            )}
          >
            <PrismicPreview repositoryName={repositoryName}>
              <Component {...pageProps} />
            </PrismicPreview>
          </PrismicProvider>
        </Layout>
      </ManagedUIContext>
    </>
  )
}
