import * as prismic from '@prismicio/client'
import * as prismicNext from '@prismicio/next'
import { FilledLinkToDocumentField, Slice } from '@prismicio/types'
import sm from 'sm.json'

export const endpoint = sm.apiEndpoint
export const repositoryName = prismic.getRepositoryName(endpoint)

// Update the Link Resolver to match your project's route structure
export function linkResolver(doc: FilledLinkToDocumentField) {
  switch (doc.type) {
    case 'landing-page':
      return `/jewellery/${doc.uid}`
    case 'help-page':
      return `/help/${doc.uid}`
    default:
      return '/'
  }
}

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - Configuration for the Prismic client.
 */
export function createClient(config: any = {}) {
  const client = prismic.createClient(endpoint, {
    ...config,
  })

  prismicNext.enableAutoPreviews({
    client,
    previewData: config?.previewData,
    req: config?.req,
  })

  return client
}

// export interface prismicPreviewData {
//   preview: boolean
//   previewData: any
//   params: any
//   locale?: string
//   locales: string[]
// }

export const getDocByUid = async (
  doctype: string,
  uid: string,
  client: prismic.Client,
  locale: string | undefined,
  fallback: boolean = true
) => {
  if (!uid) return null

  let doc = null
  try {
    doc = await client.getByUID(doctype, uid, { lang: locale })
  } catch (e) {
    console.log('Error getting doc by uid', uid, e)
  }

  if (!doc && fallback) {
    try {
      doc = await client.getByUID(doctype, uid)
    } catch (e) {
      console.log('Error getting doc by uid', uid, e)
    }
  }

  return doc
}

export const findCollectionHandles = (sliceList: Slice[]) => {
  const collectionHandles = sliceList
    ?.filter((slice: any) => slice.slice_type === 'product_slider')
    .map((slice: any) => slice?.primary?.shopifyCollectionHandle)

  return collectionHandles || []
}
