import { ThemeProvider } from 'next-themes'
import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react'

export interface State {
  displaySidebar: boolean // sidebar
  displayDropdown: boolean
  sidebarIsClosing: boolean
  displayModal: boolean
  sidebarView: string
  modalView: string
  userAvatar: string
  cartNotificationMessage: string | null
  paintCoverageCalculated: boolean
  calculatedCoverage: number | 0
}

const initialState = {
  displaySidebar: false,
  sidebarIsClosing: false,
  displayDropdown: false,
  displayModal: false,
  displayCartNotification: false,
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  userAvatar: '',
  cartNotificationMessage: null,
  paintCoverageCalculated: false,
  calculatedCoverage: 0,
  displayHelpMenu: false,
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'SIDEBAR_IS_CLOSING'
      value: boolean
    }
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'OPEN_HELP_MENU'
    }
  | {
      type: 'CLOSE_HELP_MENU'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_VIEWS
    }
  | {
      type: 'SET_SIDEBAR_VIEW'
      view: SIDEBAR_VIEWS
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }
  | {
      type: 'OPEN_CART_NOTIFICATION'
      cartNotificationMessage: string
    }
  | {
      type: 'CLOSE_CART_NOTIFICATION'
    }
  | {
      type: 'START_PAINT_COVERAGE_CALCULATION'
      coverage: number
    }
  | {
      type: 'END_PAINT_COVERAGE_CALCULATION'
      coverage: number
    }

type MODAL_VIEWS =
  // | 'SIGNUP_VIEW'
  // | 'LOGIN_VIEW'
  // | 'FORGOT_VIEW'
  // | 'NEW_SHIPPING_ADDRESS'
  | 'NEW_PAYMENT_METHOD'
  | 'COUNTRY_SELECTOR_VIEW'
  | 'SURFACE_DETAIL_MODAL_VIEW'
  | 'PAINT_CALCULATOR_MODAL_VIEW'
  | 'SURFACE_SELECTION_MODAL_VIEW'

type SIDEBAR_VIEWS =
  | 'CART_VIEW'
  | 'SEARCH_VIEW'
  // | 'CHECKOUT_VIEW'
  // | 'PAYMENT_METHOD_VIEW'
  | 'COUNTRY_SELECTOR_VIEW'
  | 'SURFACE_DETAIL_MODAL_VIEW'
  | 'PAINT_CALCULATOR_MODAL_VIEW'
  | 'SURFACE_SELECTION_MODAL_VIEW'
  | 'MOBILE_PROFILE_SIDEBAR_VIEW'
  | 'PROFILE_SIDEBAR_VIEW'
  | 'PRODUCT_SIZE_CHART'
  | 'PRODUCT_WATER_RESISTANCE'
  | 'STORE_SIDEBAR'

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'SIDEBAR_IS_CLOSING': {
      return {
        ...state,
        sidebarIsClosing: action.value,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'OPEN_HELP_MENU': {
      return {
        ...state,
        displayHelpMenu: true,
      }
    }
    case 'CLOSE_HELP_MENU': {
      return {
        ...state,
        displayHelpMenu: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarIsClosing: false,
        sidebarView: action.view,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case 'OPEN_CART_NOTIFICATION': {
      return {
        ...state,
        displayCartNotification: true,
        cartNotificationMessage: action.cartNotificationMessage,
      }
    }
    case 'CLOSE_CART_NOTIFICATION': {
      return {
        ...state,
        displayCartNotification: false,
        cartNotificationMessage: null,
      }
    }
    case 'START_PAINT_COVERAGE_CALCULATION': {
      return {
        ...state,
        paintCoverageCalculated: false,
        calculatedCoverage: action.coverage,
      }
    }
    case 'END_PAINT_COVERAGE_CALCULATION': {
      return {
        ...state,
        paintCoverageCalculated: true,
        calculatedCoverage: action.coverage,
      }
    }
  }
}

export const UIProvider: FC<PropsWithChildren> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openSidebar = useCallback(
    () => dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch]
  )
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch]
  )
  const openCartNotification = useCallback(
    (cartNotificationMessage: any) => {
      dispatch({ type: 'OPEN_CART_NOTIFICATION', cartNotificationMessage })
      setTimeout(() => {
        dispatch({ type: 'CLOSE_CART_NOTIFICATION' })
      }, 3000)
    },
    [dispatch]
  )
  const closeCartNotification = useCallback(
    () => dispatch({ type: 'CLOSE_CART_NOTIFICATION' }),
    [dispatch]
  )

  const setSideBarIsClosing = useCallback(
    (value: boolean) => dispatch({ type: 'SIDEBAR_IS_CLOSING', value }),
    [dispatch]
  )

  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: 'CLOSE_SIDEBAR' })
        : dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )

  const openDropdown = useCallback(
    () => dispatch({ type: 'OPEN_DROPDOWN' }),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({ type: 'CLOSE_DROPDOWN' }),
    [dispatch]
  )

  const openModal = useCallback(
    () => dispatch({ type: 'OPEN_MODAL' }),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({ type: 'CLOSE_MODAL' }),
    [dispatch]
  )

  const openHelpMenu = useCallback(
    () => dispatch({ type: 'OPEN_HELP_MENU' }),
    [dispatch]
  )
  const closeHelpMenu = useCallback(
    () => dispatch({ type: 'CLOSE_HELP_MENU' }),
    [dispatch]
  )

  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: 'SET_USER_AVATAR', value }),
    [dispatch]
  )

  const setModalView = useCallback(
    (view: MODAL_VIEWS) => dispatch({ type: 'SET_MODAL_VIEW', view }),
    [dispatch]
  )

  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEWS) => dispatch({ type: 'SET_SIDEBAR_VIEW', view }),
    [dispatch]
  )
  const openCalculator = useCallback(
    (coverage: number) =>
      dispatch({ type: 'START_PAINT_COVERAGE_CALCULATION', coverage }),
    [dispatch]
  )
  const closeCalculator = useCallback(
    (coverage: number) =>
      dispatch({ type: 'END_PAINT_COVERAGE_CALCULATION', coverage }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      openHelpMenu,
      closeHelpMenu,
      setModalView,
      setSidebarView,
      setUserAvatar,
      setSideBarIsClosing,
      openCartNotification,
      closeCartNotification,
      openCalculator,
      closeCalculator,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext: FC<PropsWithChildren> = ({ children }) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
)
