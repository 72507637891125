import { eventLog } from '@lib/events/general'
import {
  useShopifyCookies,
  ShopifyPageViewPayload,
  AnalyticsEventName,
  sendShopifyAnalytics,
  getClientBrowserParameters,
} from '@shopify/hydrogen-react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const analyticsShopData = {
  shopId: 'gid://shopify/Shop/18411618368',
  currency: 'AUD',
  acceptedLanguage: 'en',
}

function sendPageView(analyticsPageData: ShopifyPageViewPayload) {
  const payload: ShopifyPageViewPayload = {
    ...getClientBrowserParameters(),
    ...analyticsPageData,
  }

  eventLog(`[Shopify Analytics] Page View: ${payload.pageType}.`, {
    detail: payload,
  })
  sendShopifyAnalytics({
    eventName: AnalyticsEventName.PAGE_VIEW,
    payload,
  })
}

type Props = {
  analyticsPayload?: any
}

const ShopifyTracking = ({ analyticsPayload }: Props) => {
  const [pendingSend, setPendingSend] = useState(true)

  const hasUserConsent = true
  useShopifyCookies({
    hasUserConsent,
    domain: process.env.NEXT_PUBLIC_SITE_DOMAIN,
  })
  const router = useRouter()

  const analytics: ShopifyPageViewPayload = {
    hasUserConsent,
    ...analyticsShopData,
    ...analyticsPayload,
  }

  useEffect(() => {
    if (pendingSend) {
      sendPageView(analytics)
      setPendingSend(false)
    }
  }, [analytics])

  useEffect(() => {
    const handleRouteChange = () => {
      setPendingSend(true)
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return null
}

export default ShopifyTracking
