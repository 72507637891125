// import { getCustomerId } from '@framework/utils/customer-token';
import { getIdFromGraphQlId } from '@lib/general'

const getCustomerId = () => null

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID
export const EVENT_DEBUG = process.env.NEXT_PUBLIC_EVENT_DEBUG
export default GTM_ID

export const infoLog = (message: string) => {
  if (!EVENT_DEBUG) return

  console.log(
    '%c%s',
    'color: white; background: green; padding: 2px 10px;',
    message
  )
}

export const eventLog = (message: string, event: any) => {
  if (!EVENT_DEBUG) return

  console.log(
    '%c%s',
    'color: white; background: teal; padding: 2px 10px;',
    message,
    event ? event.detail : 'No event details available.'
  )
}

export const loginStatus = () => {
  const customerGQLId = getCustomerId()
  const customerId = customerGQLId
    ? getIdFromGraphQlId(customerGQLId, 'gid://shopify/Customer/')
    : null

  return {
    login: {
      status: customerId ? true : false,
      customer_id: customerId || null,
    },
  }
}
